import React from 'react';
import styled from 'styled-components';
import { Card } from 'semantic-ui-react';
import { Theme } from '../Theme';

export const PriceCard = ({tier, price, extra}) => (
  <StyledCard>
    <CardHeader
      textAlign="center"
      header={tier.toUpperCase()}
    />

    <CardDescription
      textAlign="center"
      description={price.toUpperCase()}
    />

    <Card.Content extra textAlign="center">
      {extra}
    </Card.Content>
  </StyledCard>
);

PriceCard.defaultProps = {
  tier: '',
  price: '',
  extra: '',
};

const StyledCard = styled(Card)`
  margin: 1em !important;
`;

const CardHeader = styled(Card.Content)`
  background-color: ${Theme.colors.primary.default} !important;
  & > .header {
    color: white !important;
    letter-spacing: 0.2em;
  }
  & > .header::selection {
    color: lightseagreen;
    background: white;
  }
`;

const CardDescription = styled(Card.Content)`
  font-size: 1.8em !important;
`;
