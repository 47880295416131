import React from 'react';
import styled from 'styled-components';
import { Underline } from '../text';
import { Theme } from '../Theme';

const Header = styled.h1`
  margin-bottom: 2em;
  text-align: center;
`;

const Container = styled.div`
  max-width: ${props => props.wide ? 'min(90vw, 1320px)' : 'min(90vw, 1100px)'};
  margin: auto;
  padding: ${props => props.pad || '5em 0em'};
  text-align: justify;
`;

export function Section(props) {
  return (
    <section id={props.id} style={props.gray && s.gray}>
      <Container wide={props.wide} pad={props.pad}>
        {props.title && (
          <Underline as={Header}>
            {props.title}
          </Underline>
        )}

        {props.children}
      </Container>
    </section>
  );
}

const s = {
  gray: {
    backgroundColor: Theme.colors.gray.background,
  },
};
