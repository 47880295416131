import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Grommet } from 'grommet';
import { useMediaQuery, useTranslation } from '../../hooks';
import { NavBar } from './NavBar';
import { Footer } from './Footer';
import { GrommetTheme } from '../Theme';

export const Page = ({ children, path }) => {
  const [visible, setVisible] = useState(false)
  const isSmall = useMediaQuery(730);
  const setHidden = useCallback(() => setVisible(false), []);

  return (
    <Grommet full theme={GrommetTheme}>
      <Container id="home">
        <Helmet>
          <title>{useTranslation('metadata.title')}</title>
          <meta name="description" content={useTranslation('metadata.description')} />
        </Helmet>

        <NavBar sticky>
          {isSmall ? (
            <NavBar.Handle
              onClick={() => setVisible(!visible)}
            />
          ) : (
            <NavBarItems />
          )}
        </NavBar>

        {isSmall && (
          <NavBar.Side
            visible={visible}
            onHide={setHidden}>

            <NavBar.Item style={{marginTop: 25}} />
            <NavBarItems onClick={setHidden} />
          </NavBar.Side>
        )}

        {children}

        <Footer />
      </Container>
    </Grommet>
  );
}

const NavBarItems = (props) => (
  <>
    <NavBar.Item {...props} name={useTranslation('lang.about')} href="/#about-us" />
    <NavBar.Item {...props} name={useTranslation('accommodation.title')} href="/#accommodation" />
    <NavBar.Item {...props} name={useTranslation('lang.pictures')} href="/#pictures" />
    <NavBar.Item {...props} name={useTranslation('amenities.title')} href="/#amenities" />
    <NavBar.Item {...props} name={useTranslation('location.title')} href="/#location" />
    <NavBar.Item {...props} name={useTranslation('lang.contactUs')} href="/#contact-us" />
  </>
)

const Container = styled.div`
  overflow-x: hidden;
`;
