import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Container, Icon, Menu, Sidebar, Visibility } from 'semantic-ui-react';
import { Theme } from '../Theme';

export const NavBar = (props) => (
  <StickyMenu {...props} />
)

/*export function NavBar(props) {
  const [ isSticky, setSticky ] = useState(false);

  const onBottomPassed = useCallback(() => {
    if (!isSticky) setSticky(true);
  }, [isSticky]);

  const onBottomVisible = useCallback(() => {
    if (isSticky) setSticky(false);
  }, [isSticky]);

  return (
    <Visibility
      once={false}
      onBottomPassed={onBottomPassed}
      onBottomVisible={onBottomVisible}>

      <StickyMenu
        {...props}
        sticky={isSticky}
      />
    </Visibility>
  );
}*/

NavBar.Item = styled(props => <Menu.Item {...props} as='a' />)`
  color: white !important;
  font-weight: 600 !important;
`;

NavBar.Handle = (props) => (
  <Menu.Menu position="right">
    <NavBar.Item {...props}>
      <Icon name="bars" />
    </NavBar.Item>
  </Menu.Menu>
)

NavBar.Side = (props) => (
  <Sidebar
    {...props}
    inverted
    vertical
    as={Menu}
    animation='overlay'
    style={{
      backgroundColor: Theme.colors.primary.default
    }}
  />
)

const StickyMenu = (props) => (
  <React.Fragment>
    {/*props.sticky && (
      <StickyMenu {...props} sticky={false} />
    )*/}

    <Menu
      borderless
      fixed={props.sticky ? 'top' : undefined}
      style={props.sticky ? s.sticky : s.menu}>

      <Container text>
        {props.header ? (
          <NavBar.Item
            header
            href="/"
            name={props.header}
          />
        ) : props.children}

        {props.header && (
          <Menu.Menu position="right">
            {props.children}
          </Menu.Menu>
        )}
      </Container>
    </Menu>
  </React.Fragment>
);

const s = {
  menu: {
    backgroundColor: Theme.colors.primary.default,
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    transition: 'box-shadow 0.5s ease, padding 0.5s ease',
  },
  sticky: {
    backgroundColor: Theme.colors.primary.default,
    border: `1px solid ${Theme.colors.primary.shade1}`,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    zIndex: 10,
  },
};
