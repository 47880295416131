import styled from 'styled-components';

export const Horizontal = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3em 0em 1em;
  &:focus {
    outline: none;
  }
`;
