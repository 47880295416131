import React  from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import { Segment, Button, Icon } from 'semantic-ui-react';
import { Theme } from '../Theme';

export const FAB = (props) => (
  <Segment style={s.segment}>
    <StyledButton {...omit(props, 'label', 'icon')}>
      <Icon name={props.icon} />

      {props.label}
    </StyledButton>
  </Segment>
)


const StyledButton = styled(Button)`
  background-color: ${Theme.colors.primary.default} !important;
  color: white !important;
  border-radius: 25px !important;
  font-size: 1.2em !important;
`;

const s = {
  segment: {
    position: 'fixed',
    right: '4%',
    bottom: '4%',
    zIndex: 10,
    margin: '0px',
    padding: '0px',
    border: 'none',
    background: 'transparent',
    boxShadow: 'none'
  },
};
