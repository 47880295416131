import React from 'react';
import { Next, Previous } from 'grommet-icons';

export const Theme = {
  navBarHeight: 54,
  colors: {
    primary: {
      default: 'lightseagreen',
      shade1: '#1ca099',
    },
    gray: {
      background: '#eee',
    },
  },
};

export const GrommetTheme = {
  global: {
    colors: {
      brand: Theme.colors.primary.default,
      focus: Theme.colors.primary.default
    }
  },
  button: {
    color: 'white',
  },
  carousel: {
    icons: {
      next: (props) => <Next {...props} size="large" />,
      previous: (props) => <Previous {...props} size="large" />,
      color: 'white',
    }
  },
};
