import React from 'react';
import { Box, Layer } from 'grommet';
import { Close } from 'grommet-icons';

export const Modal = ({open, onRequestClose, children, ...rest}) => open && (
  <Layer
    full
    onEsc={onRequestClose}
    onClickOutside={onRequestClose}
    {...rest}>

    <Box
      flex={false}
      pad="medium"
      align="end"
      style={s.close}
      background="rgba(0,0,0,0.1)">

      <Close
        color="white"
        onClick={onRequestClose}
      />
    </Box>

    {children}
  </Layer>
);

const s = {
  close: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9,
    cursor: 'pointer'
  },
}
