import { useEffect, useState } from "react";

export const useMediaQuery = (minWidth) => {
  const [isDesiredWidth, setDesiredWidth] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setDesiredWidth(window.innerWidth < minWidth);
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return isDesiredWidth;
};
