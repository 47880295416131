import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const GET_GALLERY_IMAGES = graphql`
  query MyQuery {
    allFile(filter: {dir: {regex: "/resources/gallery/"}, extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
      edges {
        node {
          name
          publicURL
          relativeDirectory
        }
      }
    }
  }
`

const findThumbnail = (node, nodes) => {
  return nodes.find(n => n.name === `${node.name}-thumbnail`);
};

export const useGallery = (extended) => {
  const result = useStaticQuery(GET_GALLERY_IMAGES);

  return useMemo(() => {
    const nodes = result.allFile.edges
        .map(edge => edge.node)
        .filter(node => extended
          ? node.relativeDirectory.includes('extended')
          : !node.relativeDirectory.includes('extended'));

    return nodes
      .filter(node => !node.name.endsWith('thumbnail'))
      .sort((n1, n2) => n1.name >= n2.name ? 1 : -1)
      .map(node => ({
        picture: node.publicURL,
        thumbnail: findThumbnail(node, nodes)?.publicURL,
      }));
  }, [result, extended]);
};
