import React from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

export const Map = withScriptjs(withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={props.zoom}
    defaultCenter={props.position}
    options={{ fullscreenControl: false }}>

    <Marker
      position={props.position}
    />
  </GoogleMap>
)));
