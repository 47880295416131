import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Carousel, Image } from 'grommet';
import { useMediaQuery } from '../../hooks';
import { Horizontal, Modal } from '../layout';

export function Gallery(props) {
  const [index, setIndex] = useState(null)
  const items = props.items || [];
  const isModalOpen = index !== null;
  const onRequestClose = useCallback(() => setIndex(null), [setIndex]);
  const isMobile = useMediaQuery(730);
  const scale = props.compact ? isMobile ? 0.4 : 0.6 : 1;

  return (
    <React.Fragment>
      <Horizontal style={{justifyContent: 'space-evenly'}}>
        {items.map((img, idx) => (
          <Thumbnail
            key={idx}
            scale={scale}
            src={img.thumbnail || img.picture}
            onClick={() => setIndex(idx)}
          />
        ))}
      </Horizontal>

      <Modal
        animate={false}
        open={isModalOpen}
        onRequestClose={onRequestClose}>

        <GalleryCarousel
          initialChild={index}
          items={items}
        />
      </Modal>
    </React.Fragment>
  );
}

const GalleryCarousel = ({initialChild, items}) => {
  const [didLoad, setDidLoad] = useState(false);
  const [index, setIndex] = useState(initialChild);
  const [loadedMap, setLoadedMap] = useState(new Map([
    [initialChild - 1, true],
    [initialChild, true],
    [initialChild + 1, true],
  ]));

  const onChild = useCallback((newIndex) => {
    const newLoadedMap = new Map(loadedMap);
    newLoadedMap.set(newIndex - 1, true);
    newLoadedMap.set(newIndex, true);
    newLoadedMap.set(newIndex + 1, true);

    setLoadedMap(newLoadedMap);
    setIndex(newIndex);
  }, [setIndex, setLoadedMap, loadedMap]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDidLoad(true), 500);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Carousel
      fill
      controls="arrows"
      activeChild={index}
      onChild={onChild}>

      {items.map((img, idx) => (
        <Image
          key={img.picture}
          fit="cover"
          src={loadedMap.get(idx) ? img.picture : null}
          style={{opacity: idx === index || didLoad ? 1 : 0}}
        />
      ))}
    </Carousel>
  );
};

const Thumbnail = styled.div`
  width: ${props => props.scale * 400}px;
  height: ${props => props.scale * 250}px;
  margin: ${props => props.scale * 1}em;
  border-radius: 5px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-image: ${props => `url(${props.src})`};
  &:hover {
    filter: brightness(0.75);
  }
`;
