import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { useTranslation } from '../../hooks';

export function Footer() {
  const year = new Date().getFullYear();
  const copyright = useTranslation('copyright', {year});

  return (
    <Segment inverted vertical style={s.segment}>
      <Container textAlign='center'>
        <List horizontal inverted divided link size='small'>
          <List.Item as='span'>
            {copyright}
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}

const s = {
  segment: {
    padding: '3em 0em',
    background: 'darkslategray',
  },
};
