import React from 'react';
import styled from 'styled-components';
import { Theme } from '../Theme';

const StyledUnderline = styled.span`
  padding: 5px;
  border-bottom: 4px solid ${props => props.color || Theme.colors.primary.default};
`;

export function Underline(props) {
  const Component = props.as || 'div';

  return (
    <Component>
      <StyledUnderline color={props.color}>
        {props.children}
      </StyledUnderline>
    </Component>
  )
}
